import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import { one } from 'images/product/salaris'
import Helmet from 'react-helmet'
import { belastingdienst , belastingdienstIllustration } from 'images'
import Layout from '../../../layouts'

class AanvragenLoonbelastingnummerPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="tools animated fadeInPage">

          <Helmet>
            <title>Tool loonbelastingnummer aanvragen | Employes</title>
            <meta name="description" content="Als je personeel in dienst hebt, moet je loonaangifte doen. Daar heb je een loonbelastingnummer voor nodig. Hieronder kun je het aanvraagformulier downloaden." />
            <meta itemprop="name" content="Tool loonbelastingnummer aanvragen | Employes" />
            <meta itemprop="description" content="Als je personeel in dienst hebt, moet je loonaangifte doen. Daar heb je een loonbelastingnummer voor nodig. Hieronder kun je het aanvraagformulier downloaden.." />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="padding-xl">
            <div className="container-md">
              <diV className="grid yg center text-center">
                <div className="col-12">
                  <p className="eyebrow">Tool</p>
                  <h1>Aanvragen loonbelastingnummer</h1>
                  <p className="streamer center">Als je personeel in dienst hebt, moet je loonaangifte doen. Daar heb je een loonbelastingnummer voor nodig. Hieronder kun je het aanvraagformulier downloaden.</p>
                </div>
              </diV>
            </div>
          </header>


          <section className="tools-cards padding-xxl">
            <div className="container-md">
              <div className="grid yg">
                <div className="col-7">

                  <div className="tools-cards-single card">
                    <div className="title margin-m-bottom">
                      <h2>Download het loonbelastingformulier</h2>
                      <p>Met dit formulier kun je een loonheffingennummer aanvragen bij de belastingdienst.</p>
                    </div>

                    <div className="form">
                      <form name="Tools | Aanvragen loonbelastingnummer" method="POST" action="/tools/aanvragen-loonbelastingnummer-voltooid/" data-netlify="true" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="Tools | Aanvragen loonbelastingnummer" />
                        <input type="text" name="company_name" placeholder="Bedrijfsnaam *" className="margin-s-bottom" autoFocus required />
                        <input type="email" name="email" placeholder="E-mailadres *" className="margin-s-bottom" required />
                        <input type="text" name="text" placeholder="Telefoonnummer *" className="margin-s-bottom" required />
                        <button type="submit" className="btn primary lg flex">Download</button>
                      </form>
                    </div>
                  </div>
                </div>


                <div className="col-5">
                  <div className="margin-s-left margin-m-top">
                    <h5>Aanvullende informatie</h5>
                    <p>Lees hieronder extra informatie over het aanmelden als werkgever bij de belastingdienst:</p>
                    <ul>
                      <li>Het duurt ongeveer 3 tot 4 weken voordat je een bevestiging met het nummer ontvangt van de belastingdienst.</li>
                      <li>Vermeld bij de activiteiten de SBI-codes die op je Kvk-uitreksel staan.</li>
                      <li>Ben je een DGA of ga je alleen een stagiair aannemen? Dan hoef je geen premies werknemersverzekeringen te betalen.</li>
                      <li>Kies voor een maandaangifte in plaats van een 4-weken aangifte. Dit is meestal makkelijker in de salarisadministratie.</li>
                      <li>Als je je inschrijft bij de kvk en je weet al zeker dat je personeel gaat aannemen, geef dit dan goed aan. Dan ontvang je namelijk automatisch een loonheffingsnummer.</li>
                    </ul>
                    <p>Toch nog iets onduidelijk? Neem contact met ons op via de chat of stuur een mail naar support@employes.nl.</p>
                  </div>
                </div>

              </div>
            </div>
          </section>

          {/*
            <section className="vision padding-l">
              <div className="container-sm">
                <div className="grid yg">
                  <div className="col-12">
                    <p className="eyebrow">Visie</p>
                    <h3>Wij helpen je graag verder</h3>
                  </div>
                  <div className="col-6">
                    <p className="margin-s-bottom">
                      For many of us, our very first experience of learning about the celestial bodies begins when we saw our first full moon in the sky. It is truly a magnificent view even to the naked eye. If the night is clear, you can see amazing detail of the lunar surface just star gazing on in your back yard.
                    </p>

                    <p className="margin-s-bottom">
                      Naturally, as you grow in your love of astronomy, you will find many celestial bodies fascinating. But the moon may always be our first love because is the one far away space object that has the unique distinction of flying close to the earth and upon which man has walked.
                    </p>

                    <p>
                      Your study of the moon, like anything else, can go from the simple to the very complex. To gaze at the moon with the naked eye, making yourself familiar with the lunar map will help you pick out the seas, craters and other geographic phenomenon that others have already mapped to make your study more enjoyable. Moon maps can be had.
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="margin-s-bottom">
                      The best time to view the moon, obviously, is at night when there are few clouds and the weather is accommodating for a long and lasting study. The first quarter yields the greatest detail of study. And don’t be fooled but the blotting out of part of the moon when it is not in full moon stage. The phenomenon known as “earthshine” gives you the ability to see the darkened part of the moon with some detail as well, even if the moon is only at quarter or half display.
                    </p>

                    <p>
                      To kick it up a notch, a good pair of binoculars can do wonders for the detail you will see on the lunar surface. For best results, get a good wide field in the binocular settings so you can take in the lunar landscape in all its beauty. And because it is almost impossible to hold the binoculars still for the length of time you will want to gaze at this magnificent body in space, you may want to add to your equipment arsenal a good tripod that you can affix.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          */}
        </main>
      </Layout>
    )
  }
}

export default AanvragenLoonbelastingnummerPage
